<template>
  <b-modal :id="modalId" :ref="modalId" title="Kontakt bearbeiten" no-close-on-backdrop hide-header-close lazy content-class="shadow" scrollable>
    <label for="firstname" class="font-weight-bold">Vorname</label>
    <b-form-input id="firstname" v-model="firstName" class="form-group" placeholder="Max"></b-form-input>

    <label for="lastname" class="font-weight-bold">Nachname</label>
    <b-form-input id="lastname" v-model="lastName" class="form-group" placeholder="Mustermann"></b-form-input>

    <InputLanguageSwitch 
      label="Position" 
      placeholder="Mitarbeiter" 
      placeholder-translation="Employee" 
      :value="position" 
      @value="position = $event"
      :value-translation="positionTranslation" 
      @value-translation="positionTranslation = $event" 
    />

    <InputLanguageSwitch 
      label="Abteilung/BU" 
      placeholder="BU Beispiel" 
      placeholder-translation="BU example" 
      :value="department" 
      @value="department = $event"
      :value-translation="departmentTranslation" 
      @value-translation="departmentTranslation = $event" 
    />

    <label for="phoneNumber" class="font-weight-bold">Telefonnummer</label>
    <b-form-input id="phoneNumber" v-model="phoneNumber" class="form-group" placeholder="+49461 77303-300"></b-form-input>

    <label for="emailAdress" class="font-weight-bold">E-Mail-Adresse</label>
    <b-form-input id="emailAdress" v-model="emailAdress" class="form-group" placeholder="info@comline-shop.de"></b-form-input>

    <label for="link" class="font-weight-bold">Shops</label>
    <p class="small">Mehrfachauswahl: CMD + Klick</p>
    <b-form-select v-model="selectedShops" :options="shops" multiple :select-size="4"></b-form-select>
    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
      <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
    </template>
  </b-modal>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import InputLanguageSwitch from '../smallComponents/InputLanguageSwitch'

import { db } from "../plugins/firebase"
import { update, ref } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'EditContactComponent',
  props: {
    modalId: String,
    selectedKey: String,
    selectedItem: Object
  },
  components: {
    SaveButtonComponent,
    InputLanguageSwitch
  },
  methods: {
    hideModal: function() {
      this.$refs['edit-contact-modal'].hide()
    },
    saveChanges: function() {
      if (
        this.firstName === '' ||
        this.lastName === '' ||
        this.position === '' ||
        this.department === '' ||
        this.phoneNumber === '' ||
        this.emailAdress === '' ||
        this.selectedShops === '' 
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        [this.selectedKey]: {
          firstName: this.firstName,
          lastName: this.lastName,
          position: this.position,
          positionTranslation: this.positionTranslation,
          department: this.department,
          departmentTranslation: this.departmentTranslation,
          phoneNumber: this.phoneNumber,
          emailAdress: this.emailAdress,
          selectedShops: this.selectedShops,
          order: this.order
        }
      }
      console.log(document)
      update(ref(db, 'contacts'), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    }
  },
  watch: {
    selectedItem: function(data) {
      this.firstName = data.firstName
      this.lastName = data.lastName
      this.position = data.position
      this.positionTranslation = data.positionTranslation
      this.department = data.department
      this.departmentTranslation = data.departmentTranslation
      this.phoneNumber = data.phoneNumber
      this.emailAdress = data.emailAdress
      this.selectedShops = data.selectedShops
      this.order = data.order
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.saveButtonSate = ''
    })
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      position: '',
      positionTranslation: '',
      department: '',
      departmentTranslation: '',
      phoneNumber: '',
      emailAdress: '',
      saveButtonSate: '',
      order: 0,
      selectedShops: [],
      shops: [
        {
          text: 'CLRent',
          value: 'clrent'
        },
        {
          text: 'Retail',
          value: 'retail'
        },
        {
          text: 'Frankreich',
          value: 'fr'
        },
        {
          text: 'Großbritannien',
          value: 'uk'
        },
        {
          text: 'Standard',
          value: 'standard'
        }
      ]
    }
  }
}
</script>