<template>
  <tr id="table-row-component">
    <td><fa-icon :icon="['fas','circle']" :class="statusClass(item.fromDate, item.toDate)" :title="status(item.fromDate, item.toDate)"></fa-icon></td>
    <td><button class="btn btn-link p-0 text-left" v-on:click="selectItem">{{item.name}}</button></td>
    <td><button class="btn btn-link p-0 text-left" v-on:click="selectItem">{{item.category}}</button></td>
    <td><button class="btn btn-link p-0 text-left" v-on:click="selectItem">{{item.position}}</button></td>
    <td><button class="btn btn-link p-0 text-left" v-on:click="selectItem">{{item.language}}</button></td>
    <td><button class="btn btn-link p-0 text-left" v-on:click="selectItem">{{item.fromDate | convertDate}} - {{item.toDate | convertDate}}</button></td>
    <td>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-sm mr-2" v-on:click="duplicate(item)" v-b-tooltip.hover title="Duplizieren"><fa-icon :icon="['fad','clone']" /></button>
        <button class="btn btn-danger btn-sm" v-on:click="deleteFromDatabase" v-b-tooltip.hover title="Löschen"><fa-icon :icon="['fas','trash']" /></button>
      </div>
    </td>
  </tr>
</template>

<script>
import moment from 'moment-timezone'

import { db, storage } from "../plugins/firebase"
import { deleteObject, ref as storageRef } from "firebase/storage"
import { ref, remove, set, push, child } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'DealsAndSpecialsRowComponent',
  props: {
    item: Object,
    itemKey: String
  },
  methods: {
    selectItem: function() {
      this.$emit('selectedItem', {
        item: this.item,
        key: this.itemKey
      })
    },
    deleteFromDatabase: function() {
      this.$bvModal.msgBoxConfirm(`Möchtest du "${this.item.name}" wirklich löschen?`, {
        title: '',
        okVariant: 'danger',
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
        size: 'sm',
        contentClass: 'shadow',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      })
      .then(value => {
        if (value === true) {
          remove(ref(db, 'dealsAndSpecials/'+ this.itemKey))
          .then(() => this.deleteFromStorage())
          .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
        }
      })
      .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
    },
    deleteFromStorage: function() {
      if (this.item.fileName !== '') {
        deleteObject(storageRef(storage, this.item.fileName))
        .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        })
      }
    },
    statusClass: function(fromDate, toDate) {
      const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
      const fromTimestamp = moment(fromDate).unix()
      const toTimestamp = moment(toDate).unix()

      return fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp ? 'text-success' : 'text-secondary'
    },
    status: function(fromDate, toDate) {
      const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
      const fromTimestamp = moment(fromDate).unix()
      const toTimestamp = moment(toDate).unix()

      return fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp ? 'Aktiv' : 'Inaktiv'
    },
    duplicate: function(document) {
      document.name += ' - Kopie'
      document.fileName = ''
      document.imageUrl = ''
      const newPostKey = push(child(ref(db), 'dealsAndSpecials')).key;
      set(ref(db, 'dealsAndSpecials/'+newPostKey), document)
      .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        })
    }
  }
}
</script>

<style scoped>
#table-row-component td:first-of-type {
  width: 40px;
}
</style>