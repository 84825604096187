<template>
  <div id="change-order-component">
    <button class="btn btn-secondary" v-b-modal="'change-order-modal'">
      <fa-icon :icon="['fas','sort']" class="mr-2" />Reihenfolge ändern
    </button>
    <b-modal id="change-order-modal" ref="change-order-modal" title="Reihenfolge ändern" no-close-on-backdrop hide-header-close content-class="shadow" scrollable :size="size">
      <table class="table" id="change-order-table">
        <draggable v-model="entries" tag="tbody">
          <tr v-for="entry in entries" :key="`entry-${entry[0]}`" v-html="rowTds(entry[1])"></tr>
        </draggable>
      </table>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import draggable from 'vuedraggable'

import {db} from "../plugins/firebase"
import {update, ref} from "firebase/database"
import { store } from "../plugins/store"


export default {
  name: 'ChangeOrderComponent',
  components: {
    SaveButtonComponent,
    draggable
  },
  props: {
    entriesProp: Object,
    dbRoot: String,
    size: String
  },
  methods: {
    rowTds: function(data) {
      if (this.dbRoot === 'contacts') {
        return `<td>${data.firstName}</td><td>${data.lastName}</td><td>${data.position}</td><td>${data.department}</td>`
      } 
      if (this.dbRoot === 'offers') {
        return `<td>${data.name}</td><td>${data.language}</td><td>${this.$options.filters.convertDate(data.fromDate)} - ${this.$options.filters.convertDate(data.toDate)}</td>`
      }
      return `<td>${data.name}</td>`
    },
    hideModal: function() {
      this.$refs['change-order-modal'].hide()
    },
    saveChanges: function() {
      this.saveButtonSate = 'saving'

      let promises = []

      this.entries.forEach((entry, index)  => {
        promises.push(update(ref(db, this.dbRoot+`/${entry[0]}`), {order: index})
        .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }))
      })

      Promise.all(promises)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
        console.error(error)
        store.dispatch("fetchAlert", {
          kind: 'danger',
          message: error,
          time: false
        })
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.entries = Object.entries(this.entriesProp)
    })
    this.$root.$on('bv::modal::hidden', () => {
      this.saveButtonSate = ''
    })
  },
  data() {
    return {
      entries: [],
      saveButtonSate: ''
    }
  }
}
</script>

<style>
#change-order-table tr:first-of-type td {
  border-top: 0 !important;
}
#change-order-table tr td:hover {
  cursor: grab !important;
}
#change-order-table tr.sortable-chosen td:hover {
  cursor: grabbing !important;
}
</style>