<template>
  <div id="app-offers-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="APP-Aktionen" category="Werbeinhalte" categoryIcon="ad" />
      <CreateAppOfferComponent />
    </div>
    <div class="pl-3 py-3">
      <div class="mt-2 alert alert-warning" v-if="offers === null">Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu ändern. 😉</div>

      <b-tabs v-else-if="Object.keys(offers).length > 0">
        <b-tab title="Aktuell" active>
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <AppOfferRowComponent v-for="(item, key) in activeOffers" :key="`app-offer-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Abgelaufen">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <AppOfferRowComponent v-for="(item, key) in inactiveOffers" :key="`app-offer-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditAppOfferComponent 
      :modalId="'edit-app-offer-modal'" 
      :selectedKey="selectedKey" 
      :selectedItem="selectedItem" />
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import SpinnerComponent from '../components/SpinnerComponent'
import CreateAppOfferComponent from '../components/CreateAppOfferComponent'
import AppOfferRowComponent from '../components/AppOfferRowComponent'
import EditAppOfferComponent from '../components/EditAppOfferComponent'
import moment from 'moment-timezone'

import {db} from "../plugins/firebase"
import { onValue, ref } from "firebase/database"

export default {
  name: 'AppOffersView',
  components: {
    PageTitleComponent,
    SpinnerComponent,
    CreateAppOfferComponent,
    AppOfferRowComponent,
    EditAppOfferComponent
  },
  methods: {
    selectItem: function(data) {
      this.selectedKey = data.key
      this.selectedItem = data.item
      this.$bvModal.show('edit-app-offer-modal')
    }
  },
  computed: {
    activeOffers: function() {
      const activeOffers = Object.entries(this.offers).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const fromTimestamp = moment(item.fromDate).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (
          (fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp) ||
          fromTimestamp >= nowTimestamp
        ) {
          return [key, item]
        }  
      })
      activeOffers.sort(function (a, b) {
        if(moment(a[1].toDate).format('x') >= moment(b[1].toDate).format('x')) {
          return 1;
        } else {
          return -1;
        }
      })
      return Object.fromEntries(activeOffers)
    },
    inactiveOffers: function() {
      const inactiveOffers = Object.entries(this.offers).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (toTimestamp < nowTimestamp) {
          return [key, item]
        }  
      })
      inactiveOffers.sort(function (a, b) {
        if(moment(a[1].toDate).format('x') >= moment(b[1].toDate).format('x')) {
          return 1;
        } else {
          return -1;
        }
      })
      return Object.fromEntries(inactiveOffers)
    }
  },
  mounted() {
    onValue(ref(db, 'appOffers'), snapshot => {
      this.offers = snapshot.val()
    })
  },
  data() {
    return {
      offers: [],
      selectedKey: '',
      selectedItem: {}
    }
  }
}
</script>

<style scoped>
#app-offers-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>