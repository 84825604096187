<template>
  <div id="social-media-icons-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Social Media Icons" category="Sonstiges" categoryIcon="cogs" />
      <div class="d-flex">
        <CreateSocialMediaIconComponent class="mr-2" />
        <ChangeOrderComponent :entriesProp="socialMediaIconsSorted" :dbRoot="'socialMediaIcons'" :size="'md'" />
      </div>
    </div>
    <div class="pl-3">
      <div class="mt-2 alert alert-warning" v-if="socialMediaIcons === null">Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu ändern. 😉</div>
      <table class="table mt-3" v-else-if="Object.keys(socialMediaIcons).length > 0">
        <tbody>
          <SocialMediaIconRowComponent v-for="(item, key) in socialMediaIconsSorted" :key="`social-media-icon-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
        </tbody>
      </table>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditSocialMediaIconComponent 
      :modalId="'edit-social-media-icon-modal'" 
      :documentKey="selectedKey" 
      :documentBody="selectedItem" />
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import CreateSocialMediaIconComponent from '../components/CreateSocialMediaIconComponent'
import EditSocialMediaIconComponent from '../components/EditSocialMediaIconComponent'
import SpinnerComponent from '../components/SpinnerComponent'
import SocialMediaIconRowComponent from '../components/SocialMediaIconRowComponent'
import ChangeOrderComponent from '../components/ChangeOrderComponent'
import _ from 'lodash'

import {db} from "../plugins/firebase"
import { onValue, ref } from "firebase/database"

export default {
  name: 'SocialMediaIconsView',
  components: {
    PageTitleComponent,
    CreateSocialMediaIconComponent,
    EditSocialMediaIconComponent,
    SpinnerComponent,
    SocialMediaIconRowComponent,
    ChangeOrderComponent
  },
  methods: {
    selectItem: function(data) {
      this.selectedKey = data.key
      this.selectedItem = data.item
      this.$bvModal.show('edit-social-media-icon-modal')
    }
  },
  computed: {
    socialMediaIconsSorted: function() {
      const icons = Object.entries(this.socialMediaIcons).map(([key, value]) => {
        return [key, value]
      })

      const sorted = _.orderBy(icons, [(item) => {
        return item[1].order
      }], ['asc'])

      return Object.fromEntries(sorted)
    }
  },
  mounted() {
    onValue(ref(db, 'socialMediaIcons'), snapshot => {
      this.socialMediaIcons = snapshot.val()
    })
  },
  data() {
    return {
      socialMediaIcons: {},
      selectedKey: '',
      selectedItem: {}
    }
  }
}
</script>

<style scoped>
#social-media-icons-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>