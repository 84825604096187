<template>
  <div id="create-shopping-cart-pop-up-offer-component">
    <button class="btn btn-primary" v-b-modal="'create-shopping-cart-pop-up-offer-modal'">
      <fa-icon :icon="['fas','plus']" class="mr-2" />Erstellen
    </button>
    <b-modal id="create-shopping-cart-pop-up-offer-modal" ref="create-shopping-cart-pop-up-offer-modal" title="Warenkorb Pop-Up erstellen" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="name" class="font-weight-bold">Beschreibung</label>
      <b-form-input id="name" v-model="name" class="form-group" placeholder="Aktion"></b-form-input>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" />

      <label for="language" class="font-weight-bold">Sprache</label>
      <b-form-select id="language" v-model="language" :options="languageOptions" class="form-group"></b-form-select>

      <label class="font-weight-bold">Anzeigezeitraum</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="datepicker-from-date"
      >
        <b-form-datepicker id="datepicker-from-date" v-model="fromDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-to-date"
      >
        <b-form-datepicker id="datepicker-to-date" v-model="toDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>

      <label class="font-weight-bold">Bild</label>
      <table class="mb-3 text-muted" cellpadding="2">
        <tbody>
          <tr>
            <td>Bildgröße (px):</td>
            <td>600x250</td>
          </tr>
          <tr>
            <td>Max. Dateigröße (mb):</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Erlaubte Dateiformate:</td>
            <td>jpg, png</td>
          </tr>
        </tbody>
      </table>
      <UploadImageComponent v-on:imageUpload="imageUploaded"  :imageProp="''" class="form-group" />

      <label for="content" class="font-weight-bold">Text</label>
      <quill-editor 
        v-model="content"
        class="form-group"
        :options="editorOptions">
      </quill-editor>

      <label class="font-weight-bold">Verknüpfte Geräte</label>
      <DeviceMappingComponent v-on:updatedArticlenumbers="updatedArticlenumbers" :articlenumbersProp="[]" />

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import UploadImageComponent from './UploadImageComponent'
import DeviceMappingComponent from './DeviceMappingComponent'
import LinkComponent from './LinkComponent'
import moment from 'moment-timezone'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

import { db } from "../plugins/firebase"
import { set, ref, push, child } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'CreateShoppingCartPopUpOfferComponent',
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent,
    DeviceMappingComponent,
    quillEditor
  },
  methods: {
    hideModal: function() {
      this.$refs['create-shopping-cart-pop-up-offer-modal'].hide()
    },
    imageUploaded: function(image) {
      this.imageUrl = image.url
      this.fileName = image.fileName
    },
    saveChanges: function() {
      if (
        this.name === '' ||
        this.fromDate === '' ||
        this.toDate === '' ||
        this.imageUrl === '' ||
        this.link.url === '' ||
        this.fileName === '' ||
        this.content === '' ||
        this.language === '' ||
        this.articlenumbers.length === 0
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        name: this.name,
        language: this.language,
        fromDate: this.fromDate,
        toDate: this.toDate,
        imageUrl: this.imageUrl,
        link: this.link,
        fileName: this.fileName,
        content: this.content,
        articlenumbers: this.articlenumbers
      }
      const newPostKey = push(child(ref(db), 'shoppingCartPopUpOffers')).key;
      set(ref(db, 'shoppingCartPopUpOffers/'+newPostKey), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    },
    updatedArticlenumbers: function(data) {
      this.articlenumbers = data
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.name = ''
      this.fromDate = moment().tz('Europe/Berlin').format('YYYY-MM-DD')
      this.toDate = moment().tz('Europe/Berlin').add(1, 'd').format('YYYY-MM-DD')
      this.imageUrl = ''
      this.link = {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      }
      this.fileName = ''
      this.content = ''
      this.articlenumbers = []
      this.saveButtonSate = ''
      this.language = 'DE'
    })
  },
  data() {
    return {
      name: '',
      fromDate: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      toDate: moment().tz('Europe/Berlin').add(1, 'd').format('YYYY-MM-DD'),
      imageUrl: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      fileName: '',
      saveButtonSate: '',
      articlenumbers: [],
      content: '',
      language: 'DE',
      languageOptions: [
        'DE',
        'EN',
        'FR'
      ],
      editorOptions: {
        theme: 'snow',
        placeholder: 'Text eingeben',
        modules: {
          toolbar: [
            [{ 'header': [4, false] }],
            [
              'bold', 
              'italic'
            ],
            [
              { 'list': 'ordered'}, 
              { 'list': 'bullet' }
            ],
            [
              { 'indent': '-1'}, 
              { 'indent': '+1' }
            ],
            [
              'link'
            ]
          ]
        }
      }
    }
  }
}
</script>
