<template>
  <div id="shopping-cart-dpp-offers-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Warenkorb DPP-Aktionen" category="Werbeinhalte" categoryIcon="ad" />
      <CreateShoppingCartDppOfferComponent />
    </div>
    <div class="pl-3 py-3">
      <div class="mt-2 alert alert-warning" v-if="offers === null">Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu ändern. 😉</div>
      <b-tabs v-else-if="Object.keys(offers).length > 0">
        <b-tab title="Aktuell" active v-if="Object.keys(activeOffers).length > 0">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ShoppingCartDppOfferRowComponent v-for="(item, key) in activeOffers" :key="`shopping-cart-dpp-offer-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Abgelaufen" v-if="Object.keys(inactiveOffers).length > 0">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ShoppingCartDppOfferRowComponent v-for="(item, key) in inactiveOffers" :key="`shopping-cart-dpp-offer-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditShoppingCartDppOfferComponent
      :modalId="'edit-shopping-cart-dpp-offer-modal'" 
      :selectedKey="selectedKey" 
      :selectedItem="selectedItem" />
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import SpinnerComponent from '../components/SpinnerComponent'
import CreateShoppingCartDppOfferComponent from '../components/CreateShoppingCartDppOfferComponent'
import EditShoppingCartDppOfferComponent from '../components/EditShoppingCartDppOfferComponent'
import ShoppingCartDppOfferRowComponent from '../components/ShoppingCartDppOfferRowComponent'
import _ from 'lodash'
import moment from 'moment-timezone'

import {db} from "../plugins/firebase"
import { onValue, ref } from "firebase/database"

export default {
  name: 'ShoppingCartDppOffersView',
  components: {
    PageTitleComponent,
    SpinnerComponent,
    CreateShoppingCartDppOfferComponent,
    EditShoppingCartDppOfferComponent,
    ShoppingCartDppOfferRowComponent
  },
  methods: {
    selectItem: function(data) {
      this.selectedKey = data.key
      this.selectedItem = data.item
      this.$bvModal.show('edit-shopping-cart-dpp-offer-modal')
    }
  },
  computed: {
    activeOffers: function() {
      const offers = Object.entries(this.offers).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const fromTimestamp = moment(item.fromDate).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (
          (fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp) ||
          fromTimestamp >= nowTimestamp
        ) {
          return [key, item]
        } 
      })

      const offersSorted = _.orderBy(offers, [(item) => {
        return moment(item[1].toDate)
      }], ['asc'])

      return Object.fromEntries(offersSorted)
    },
    inactiveOffers: function() {
      const offers = Object.entries(this.offers).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (toTimestamp < nowTimestamp) {
          return [key, item]
        } 
      })

      const offersSorted = _.orderBy(offers, [(item) => {
        return moment(item[1].toDate)
      }], ['desc'])

      return Object.fromEntries(offersSorted)
    }
  },
  mounted() {
    onValue(ref(db, 'shoppingCartDppOffers'), snapshot => {
      this.offers = snapshot.val()
    })
  },
  data() {
    return {
      offers: [],
      selectedKey: '',
      selectedItem: {}
    }
  }
}
</script>

<style scoped>
#shopping-cart-dpp-offers-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>