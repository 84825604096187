import SignInView from '../../views/SignInView.vue'
import SignedInView from '../../views/SignedInView.vue'
import DashboardView from '../../views/DashboardView.vue'
import SocialMediaIconsView from '../../views/SocialMediaIconsView.vue'
import ImprintView from '../../views/ImprintView.vue'
import PrivacyView from '../../views/PrivacyView.vue'
import TermsAndConditionsView from '../../views/TermsAndConditionsView.vue'
import OffersView from '../../views/OffersView.vue'
import ContactsView from '../../views/ContactsView.vue'
import EventsView from '../../views/EventsView.vue'
import DealsAndSpecialsView from '../../views/DealsAndSpecialsView.vue'
import HomepageSliderView from '../../views/HomepageSliderView.vue'
import DppOffersView from '../../views/DppOffersView.vue'
import ShoppingCartDppOffersView from '../../views/ShoppingCartDppOffersView.vue'
import EdpOffersView from '../../views/EdpOffersView.vue'
import AppOffersView from '../../views/AppOffersView.vue'
import ShoppingCartPopUpOffersView from '../../views/ShoppingCartPopUpOffersView.vue'
import DPPCountdown from '../../views/DPPCountdown.vue'
import NotAllowed from '../../views/NotAllowedView.vue'

const routes = [
  { 
    path: '*',
    redirect: '/signin' 
  },
  { 
    path: '/', 
    redirect: '/signin' 
  },
  { 
    path: '/signin', 
    name: 'SignInView',
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  },
  { 
    path: '/token/:token', 
    name: 'token',
    component: SignInView,
    meta: {
      requiresAuth: false
    }
  },
  { 
    path: '/notallowed', 
    name: 'NotAllowed',
    component: NotAllowed,
    meta: {
      requiresAuth: true
    }
  },
  { 
    path: '/a/dashboard', 
    component: SignedInView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', 
        name: 'DashboardView',
        component: DashboardView,
      },
      { 
        path: '/a/social-media-icons', 
        name: 'SocialMediaIconsView', 
        component: SocialMediaIconsView,
      },
      { 
        path: '/a/imprint', 
        name: 'ImprintView', 
        component: ImprintView,
      },
      { 
        path: '/a/privacy', 
        name: 'PrivacyView', 
        component: PrivacyView,
      },
      { 
        path: '/a/terms-and-conditions', 
        name: 'TermsAndConditionsView', 
        component: TermsAndConditionsView,
      },
      { 
        path: '/a/offers', 
        name: 'OffersView', 
        component: OffersView,
      },
      { 
        path: '/a/contacts', 
        name: 'ContactsView', 
        component: ContactsView,
      },
      { 
        path: '/a/events', 
        name: 'EventsView', 
        component: EventsView,
      },
      { 
        path: '/a/deals-and-specials', 
        name: 'DealsAndSpecialsView', 
        component: DealsAndSpecialsView,
      },
      { 
        path: '/a/homepage-slider', 
        name: 'HomepageSliderView', 
        component: HomepageSliderView,
      },
      { 
        path: '/a/dpp-offers', 
        name: 'DppOffersView', 
        component: DppOffersView,
      },
      { 
        path: '/a/edp-offers', 
        name: 'EdpOffersView', 
        component: EdpOffersView,
      },
      { 
        path: '/a/app-offers', 
        name: 'AppOffersView', 
        component: AppOffersView,
      },
      { 
        path: '/a/shopping-cart-dpp-offers', 
        name: 'ShoppingCartDppOffersView', 
        component: ShoppingCartDppOffersView,
      },
      { 
        path: '/a/shopping-cart-pop-up-offers', 
        name: 'ShoppingCartPopUpOffersView', 
        component: ShoppingCartPopUpOffersView,
      },
      { 
        path: '/a/dpp-countdown', 
        name: 'DPPCountdown', 
        component: DPPCountdown,
      }

      
    ] 
  }
]

export default routes