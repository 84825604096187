<template>
  <div id="homepage-slider-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Homepage Slides" category="Werbeinhalte" categoryIcon="ad" />
      <CreateHomepageSlideComponent />
    </div>
    <div class="pl-3 py-3">
      <div class="mt-2 alert alert-warning" v-if="slides === null">Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu ändern. 😉</div>
      <b-tabs v-else-if="Object.keys(slides).length > 0">
        <b-tab title="Aktuell" active>
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Shops</th>
                <th>Sprache</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <HomepageSliderRowComponent v-for="(item, key) in activeSlides" :key="`event-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
        <b-tab title="Abgelaufen">
          <table class="table mt-3">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Shops</th>
                <th>Sprache</th>
                <th>Anzeigezeitraum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <HomepageSliderRowComponent v-for="(item, key) in inactiveSlides" :key="`event-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
            </tbody>
          </table>
        </b-tab>
      </b-tabs>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditHomepageSlideComponent 
      :modalId="'edit-homepage-slide-modal'" 
      :selectedKey="selectedKey" 
      :selectedItem="selectedItem" />
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import SpinnerComponent from '../components/SpinnerComponent'
import CreateHomepageSlideComponent from '../components/CreateHomepageSlideComponent'
import HomepageSliderRowComponent from '../components/HomepageSliderRowComponent'
import EditHomepageSlideComponent from '../components/EditHomepageSlideComponent'
import _ from 'lodash'
import moment from 'moment-timezone'

import {db} from "../plugins/firebase"
import { onValue, ref } from "firebase/database"

export default {
  name: 'HomepageSliderView',
  components: {
    PageTitleComponent,
    SpinnerComponent,
    CreateHomepageSlideComponent,
    HomepageSliderRowComponent,
    EditHomepageSlideComponent
  },
  methods: {
    selectItem: function(data) {
      this.selectedKey = data.key
      this.selectedItem = data.item
      this.$bvModal.show('edit-homepage-slide-modal')
    }
  },
  computed: {
    activeSlides: function() {
      const slides = Object.entries(this.slides).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const fromTimestamp = moment(item.fromDate).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (
          (fromTimestamp <= nowTimestamp && toTimestamp >= nowTimestamp) ||
          fromTimestamp >= nowTimestamp
        ) {
          return [key, item]
        }  
      })

      const slidesSorted = _.orderBy(slides, [(item) => {
        return moment(item[1].toDate)
      }], ['asc'])

      return Object.fromEntries(slidesSorted)
    },
    inactiveSlides: function() {
      const slides = Object.entries(this.slides).filter(([key, item]) => {
        const nowTimestamp = moment(moment().tz('Europe/Berlin').format('YYYY-MM-DD')).unix()
        const toTimestamp = moment(item.toDate).unix()

        if (toTimestamp < nowTimestamp) {
          return [key, item]
        }  
      })

      const slidesSorted = _.orderBy(slides, [(item) => {
        return moment(item[1].toDate)
      }], ['desc'])

      return Object.fromEntries(slidesSorted)
    },
  },
  mounted() {
    onValue(ref(db, 'homepageSlides'), snapshot => {
      this.slides = snapshot.val()
    })
  },
  data() {
    return {
      slides: [],
      selectedKey: '',
      selectedItem: {}
    }
  }
}
</script>

<style scoped>
#homepage-slider-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>