<template>
  <div id="device-mapping-component">
    <form v-on:submit.prevent="validateArticlenumber">
      <div class="input-group">
        <input type="text" class="form-control" v-model="newArticlenumber" placeholder="Neue Artikelnummer">
        <div class="input-group-append">
          <button class="btn btn-primary" type="submit" v-b-tooltip.hover title="Hinzufügen"><fa-icon :icon="['fas','plus']" /></button>
        </div>
      </div>
    </form>
    <hr v-show="articlenumbers.length > 0">
    <div class="input-group mb-2" v-for="(element, index) in articlenumbers" :key="`articlenumber-${index}`">
      <input type="text" class="form-control" v-model="articlenumbers[index]">
      <div class="input-group-append">
        <button class="btn btn-danger" type="button" v-on:click="deleteArticlenumber(index)" v-b-tooltip.hover title="Löschen"><fa-icon :icon="['fas','trash']" /></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeviceMappingComponent',
  props: {
    articlenumbersProp: Array
  },
  methods: {
    validateArticlenumber: function() {
      if (this.newArticlenumber === '') {
        return
      }
      this.addArticlenumber()
    },
    addArticlenumber: function() {
      this.articlenumbers.push(this.newArticlenumber.trim())
      this.newArticlenumber = ''
    },
    deleteArticlenumber: function(index) {
      this.articlenumbers.splice(index, 1)
    },
  },
  watch: {
    articlenumbers: {
      handler: function(data) {
        this.$emit('updatedArticlenumbers', data)
      },
      deep: true
    }
  },
  mounted() {
    if (this.articlenumbersProp.length > 0 && this.articlenumbersProp !== undefined) {
      this.articlenumbers = this.articlenumbersProp
    }
  },
  data() {
    return {
      newArticlenumber: '',
      articlenumbers: []
    }
  }
}
</script>