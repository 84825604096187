<template>
  <div id="create-contact-component">
    <button class="btn btn-primary" v-b-modal="'create-contact-modal'">
      <fa-icon :icon="['fas','plus']" class="mr-2" />Erstellen
    </button>
    <b-modal id="create-contact-modal" ref="create-contact-modal" title="Kontakt erstellen" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="firstname" class="font-weight-bold">Vorname</label>
      <b-form-input id="firstname" v-model="firstName" class="form-group" placeholder="Max"></b-form-input>

      <label for="lastname" class="font-weight-bold">Nachname</label>
      <b-form-input id="lastname" v-model="lastName" class="form-group" placeholder="Mustermann"></b-form-input>

    <InputLanguageSwitch 
      label="Position" 
      placeholder="Mitarbeiter" 
      placeholder-translation="Employee" 
      :value="position" 
      @value="position = $event"
      :value-translation="positionTranslation" 
      @value-translation="positionTranslation = $event" 
    />

    <InputLanguageSwitch 
      label="Abteilung/BU" 
      placeholder="BU Beispiel" 
      placeholder-translation="BU example" 
      :value="department" 
      @value="department = $event"
      :value-translation="departmentTranslation" 
      @value-translation="departmentTranslation = $event" 
    />

      <label for="phoneNumber" class="font-weight-bold">Telefonnummer</label>
      <b-form-input id="phoneNumber" v-model="phoneNumber" class="form-group" placeholder="+49461 77303-300"></b-form-input>

      <label for="emailAdress" class="font-weight-bold">E-Mail-Adresse</label>
      <b-form-input id="emailAdress" v-model="emailAdress" class="form-group" placeholder="info@comline-shop.de"></b-form-input>

      <label for="link" class="font-weight-bold">Shops</label>
      <p class="small">Mehrfachauswahl: CMD + Klick</p>
      <b-form-select v-model="selectedShops" :options="shops" multiple :select-size="4"></b-form-select>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import InputLanguageSwitch from '../smallComponents/InputLanguageSwitch'
// import validator from 'validator'

import {db} from "../plugins/firebase"
import {set, ref, push, child, query, orderByChild, limitToLast, get} from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'CreateContactComponent',
  components: {
    SaveButtonComponent,
    InputLanguageSwitch
  },
  methods: {
    hideModal: function() {
      this.$refs['create-contact-modal'].hide()
    },
    saveChanges: async function() {
      if (
        this.firstName === '' ||
        this.lastName === '' ||
        this.position === '' ||
        this.department === '' ||
        this.phoneNumber === '' ||
        this.emailAdress === '' ||
        this.selectedShops.length === 0
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        firstName: this.firstName,
        lastName: this.lastName,
        position: this.position,
        positionTranslation: this.positionTranslation,
        department: this.department,
        departmentTranslation: this.departmentTranslation,
        phoneNumber: this.phoneNumber,
        emailAdress: this.emailAdress,
        selectedShops: this.selectedShops,
        order: await this.nextOrderNumber()
      }
      const newPostKey = push(child(ref(db), 'contacts')).key;
      set(ref(db, 'contacts/'+ newPostKey), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    async nextOrderNumber(){
      // let lastNumber = await this.$database.ref("contacts").orderByChild("order").limitToLast(1).once("value")
      let lastNumber = await get(query(ref(db, "contacts"), orderByChild("order"), limitToLast(1)))
      const val = Object.values(lastNumber)[0].val()
      console.log(Object.values(val)[0].order)
      return Object.values(val)[0].order +1
    }
  },
  async mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.firstName = ''
      this.lastName = ''
      this.position = ''
      this.positionTranslation = ''
      this.department = ''
      this.departmentTranslation = ''
      this.phoneNumber = ''
      this.emailAdress = ''
      this.saveButtonSate = ''
      this.selectedShops = []
    })
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      position: '',
      positionTranslation: '',
      department: '',
      departmentTranslation: '',
      phoneNumber: '',
      emailAdress: '',
      saveButtonSate: '',
      selectedShops: [],
      shops: [
        {
          text: 'CLRent',
          value: 'clrent'
        },
        {
          text: 'Retail',
          value: 'retail'
        },
        {
          text: 'Frankreich',
          value: 'fr'
        },
        {
          text: 'Großbritannien',
          value: 'uk'
        },
        {
          text: 'Standard',
          value: 'standard'
        }
      ]
    }
  }
}
</script>