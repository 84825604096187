<template>
  <b-navbar type="dark" class="border-right bg-primary d-flex flex-column align-items-stretch p-0" fixed>
    <b-navbar-brand href="#" class="m-0 py-0 d-flex align-items-center px-3 flex-shrink-0">
      <LogoComponent :fill="'#fff'" :height="'30px'" :width="'30px'" class="mr-2 flex-shrink-0" />
      <strong>Shop Adminpanel</strong>
    </b-navbar-brand>
    <div class="h-100 d-flex flex-column align-items-stretch justify-content-between pb-3">
      <div>
        <div class="mx-3 text-muted text-uppercase">
          <small><small>
            <fa-icon icon="home" fixed-width class="mr-1" />Main
          </small></small>
        </div>
        <ul class="nav flex-column mb-3">
          <li class="nav-item">
            <router-link :to="{ name: 'DashboardView' }" class="nav-link text-body">
              Dashboard
            </router-link>
          </li>
        </ul>
        <div class="mx-3 blue-border border-top pt-3 mt-3 pb-2 text-muted text-uppercase">
          <small><small>
            <fa-icon icon="align-left" fixed-width class="mr-1" />Texte
          </small></small>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link :to="{ name: 'TermsAndConditionsView' }" class="nav-link text-body">
              AGB
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'PrivacyView' }" class="nav-link text-body">
              Datenschutz
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ImprintView' }" class="nav-link text-body">
              Impressum
            </router-link>
          </li>
        </ul>
        <div class="mx-3 blue-border border-top pt-3 mt-3 pb-2 text-muted text-uppercase">
          <small><small>
            <fa-icon icon="ad" fixed-width class="mr-1" />Werbeinhalte
          </small></small>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link :to="{ name: 'DealsAndSpecialsView' }" class="nav-link text-body">
              Deals und Specials
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'DppOffersView' }" class="nav-link text-body">
              DPP-Aktionen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'EdpOffersView' }" class="nav-link text-body">
              eDP-Aktionen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'AppOffersView' }" class="nav-link text-body">
              APP-Aktionen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'EventsView' }" class="nav-link text-body">
              Events
            </router-link>
          </li>
          
          <li class="nav-item">
            <router-link :to="{ name: 'OffersView' }" class="nav-link text-body">
              Herstelleraktionen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'HomepageSliderView' }" class="nav-link text-body">
              Homepage Slides
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ShoppingCartDppOffersView' }" class="nav-link text-body">
              Warenkorb DPP-Aktionen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'ShoppingCartPopUpOffersView' }" class="nav-link text-body">
              Warenkorb Pop-Ups
            </router-link>
          </li>
        </ul>
        <div class="mx-3 blue-border border-top pt-3 mt-3 pb-2 text-muted text-uppercase">
          <small><small>
            <fa-icon icon="cogs" fixed-width class="mr-1" />Sonstiges
          </small></small>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link :to="{ name: 'ContactsView' }" class="nav-link text-body">
              Kontakte
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'SocialMediaIconsView' }" class="nav-link text-body">
              Social Media Icons
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'DPPCountdown' }" class="nav-link text-body">
              DPP-Countdown
            </router-link>
          </li>
        </ul>
      </div>
      <div>
        <hr class="mt-3 mx-3 blue-border">
        <ul class="nav flex-column">
          <li class="nav-item m-0">
            <a href="#" v-on:click="abmelden" class="nav-link text-muted">
              <fa-icon icon="sign-out" fixed-width class="mr-2" />Abmelden
            </a>
          </li>
        </ul>
      </div>
    </div>
  </b-navbar>
</template>

<script>
import LogoComponent from '../components/LogoComponent'
import { auth } from "../plugins/firebase"
import { signOut } from "firebase/auth"

export default {
  name: 'SidebarComponent',
  components: {
    LogoComponent
  },
  methods: {
    abmelden: function() {
      signOut(auth)
      .then(() => this.$router.replace('/'))
      .catch(error => console.error(error.message)) // eslint-disable-line no-console
    }
  }
}
</script>

<style scoped>
.navbar {
  max-width: 250px;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1;
}
.navbar-brand {
  height: 120px;
}
.nav-link {
  hyphens: auto;
  text-decoration: none;
}
.router-link-exact-active {
  border-right: 3px solid #fff;
}
.sub-nav {
  margin-left: 10px;
}
.blue-border {
  border-color: #5f9cd2 !important;
}
.router-link-active {
  border-left: 3px solid #fff;
  color: #fff !important;
}
a, small > small {
  color: rgba(255, 255, 255, 0.788) !important;
}
a:hover {
  color: rgb(255, 255, 255) !important;
}
a strong {
  color: #fff;
}
</style>