<template>
  <b-button :variant="variant" :disabled="saveButtonSate !== ''" v-on:click="$emit('submitSaveButton')">
    <fa-icon v-if="saveButtonSate === 'saving'" :icon="['fad', 'spinner-third']" :spin="true" class="mr-1" />
    <fa-icon v-if="saveButtonSate === 'done'" :icon="['fas', 'check']" class="mr-1" />
    <fa-icon v-if="saveButtonSate === 'error'" :icon="['fas', 'times']" class="mr-1" />
    {{text}}
  </b-button>
</template>

<script>
export default {
  name: 'SaveButtonComponent',
  props: {
    saveButtonSate: String
  },
  computed: {
    variant: function() {
      if (this.saveButtonSate === 'saving') {
        return 'primary'
      } else if (this.saveButtonSate === 'done') {
        return 'success'
      } else if (this.saveButtonSate === 'error') {
        return 'danger'
      } else {
        return 'primary'
      }
    },
    text: function() {
      if (this.saveButtonSate === 'saving') {
        return 'Bitte warten'
      } else if (this.saveButtonSate === 'done') {
        return 'Erledigt'
      } else if (this.saveButtonSate === 'error') {
        return 'Fehler'
      } else {
        return 'Speichern'
      }
    }
  }
}
</script>