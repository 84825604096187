<template>
  <div class="container-fluid pl-0">
    <ShowAlert/>
    <SidebarComponent class="h-100" />
    <router-view></router-view>
  </div>
</template>

<script>
import SidebarComponent from '../components/SidebarComponent'

export default {
  name: 'SignedInView',
  components: {
    SidebarComponent
  }
}
</script>

<style scoped>
.container-fluid {
  max-width: 1480px;
  background-color: #f8f9fa;
}
</style>