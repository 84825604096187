<template>
  <div class="alerts" v-if="alert.message">
    <b-container>
      <b-alert dismissible :variant="alert.kind" @dismissed="resetAlert()" :show="alert.time" >
        {{alert.message}}
      </b-alert>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { store } from '../plugins/store'

export default {
  name: 'ShowAlert',
  data(){
    return{
      kind: 'danger',
      message: 'TEST'
    }
  },
  computed: {
    ...mapGetters([
      'alert',
    ])
  },
  methods:{
    resetAlert(){
      store.dispatch("fetchAlert", {
      kind: null,
      message: ''
    })
    }
  }
}
</script>

<style scoped>
  .alerts {
    position: fixed; 
    top: 10px;
    left: 0;
    z-index: 99999;
    width: 100%;
    padding: 20px;
  }
</style>

