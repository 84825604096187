<template>
  <div id="edit-shopping-cart-dpp-offer-component">
    <b-modal id="edit-shopping-cart-dpp-offer-modal" ref="edit-shopping-cart-dpp-offer-modal" title="Warenkorb DPP-Aktionen bearbeiten" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="name" class="font-weight-bold">Beschreibung</label>
      <b-form-input id="name" v-model="name" class="form-group"></b-form-input>

      <label for="link" class="font-weight-bold">Link</label>
      <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

      <label class="font-weight-bold">Anzeigezeitraum</label>
      <b-form-group
        label-cols-sm="1"
        label="Von"
        label-for="datepicker-from-date"
      >
        <b-form-datepicker id="datepicker-from-date" v-model="fromDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-to-date"
      >
        <b-form-datepicker id="datepicker-to-date" v-model="toDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>

      <label class="font-weight-bold">Banner</label>
      <table class="mb-3 text-muted" cellpadding="2">
        <tbody>
          <tr>
            <td>Bildgröße (px):</td>
            <td>728x90</td>
          </tr>
          <tr>
            <td>Max. Dateigröße (mb):</td>
            <td>1</td>
          </tr>
          <tr>
            <td>Erlaubte Dateiformate:</td>
            <td>jpg, png</td>
          </tr>
        </tbody>
      </table>
      <UploadImageComponent v-on:imageUpload="imageUploaded"  :imageProp="imageUrl" class="form-group" />

      <label class="font-weight-bold">Verknüpfte Geräte</label>
      <DeviceMappingComponent v-on:updatedArticlenumbers="updatedArticlenumbers" :articlenumbersProp="articlenumbers" />

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import UploadImageComponent from './UploadImageComponent'
import LinkComponent from './LinkComponent'
import DeviceMappingComponent from './DeviceMappingComponent'
import moment from 'moment-timezone'

import { db } from "../plugins/firebase"
import { update, ref } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'EditShoppingCartDppOfferComponent',
  props: {
    selectedKey: String,
    selectedItem: Object,
    modalId: String
  },
  components: {
    SaveButtonComponent,
    UploadImageComponent,
    LinkComponent,
    DeviceMappingComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['edit-shopping-cart-dpp-offer-modal'].hide()
    },
    imageUploaded: function(image) {
      this.imageUrl = image.url
      this.fileName = image.fileName
    },
    saveChanges: function() {
      if (
        this.name === '' ||
        this.link.url === '' ||
        this.imageUrl === '' ||
        this.fileName === '' ||
        this.fromDate === '' ||
        this.toDate === '' ||
        this.articlenumbers.length === 0
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        [this.selectedKey]: {
          name: this.name,
          link: this.link,
          imageUrl: this.imageUrl,
          fileName: this.fileName,
          fromDate: this.fromDate,
          toDate: this.toDate,
          articlenumbers: this.articlenumbers
        }
      }

      update(ref(db, 'shoppingCartDppOffers'), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    },
    updatedArticlenumbers: function(data) {
      this.articlenumbers = data
    }
  },
  watch: {
    selectedItem: function(data) {
      this.name = data.name
      this.link = data.link
      this.imageUrl = data.imageUrl
      this.fileName = data.fileName
      this.fromDate = data.fromDate
      this.toDate = data.toDate
      this.articlenumbers = data.articlenumbers
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.saveButtonSate = ''
    })
  },
  data() {
    return {
      saveButtonSate: '',
      name: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      imageUrl: '',
      fileName: '',
      fromDate: moment().tz('Europe/Berlin').format('YYYY-MM-DD'),
      toDate: moment().tz('Europe/Berlin').add(1, 'd').format('YYYY-MM-DD'),
      articlenumbers: []
    }
  }
}
</script>