<template>
  <div id="dpp-countdown-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="DPP Countdown" category="Sonstiges" categoryIcon="cogs" />
      <div class="d-flex">
      </div>
    </div>
    <SpinnerComponent v-if="loading"/>
    <div class=" p-3" v-else>
      <label class="font-weight-bold">Quatal</label>
      <b-form-group
        label-cols-sm="1"
        label-for="datepicker-from-date"
      >
        <b-form-input id="name" v-model="text" class="form-group" placeholder="DPP Anmeldeschluss für Q4 ist in:"></b-form-input>
      </b-form-group>

      <label class="font-weight-bold">Countdown</label>
      <b-form-group
        label-cols-sm="1"
        label="Bis"
        label-for="datepicker-from-date"
      >
        <b-form-datepicker id="datepicker-from-date" v-model="formDate" :locale="'de-DE'"></b-form-datepicker>
      </b-form-group>

      <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />

    </div>
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import SaveButtonComponent from '../components/SaveButtonComponent'
import SpinnerComponent from '../components/SpinnerComponent'

import { store } from "../plugins/store"

import moment from 'moment-timezone'

import { db } from "../plugins/firebase"
import { update, ref, onValue } from "firebase/database"

export default {
  name: 'DppCountdownView',
  components: {
    PageTitleComponent,
    SaveButtonComponent,
    SpinnerComponent
  },
  data(){
    return {
      loading : true,
      formDate: moment().tz('Europe/Berlin').add(1, 'd').format('YYYY-MM-DD'),
      text: null,
      saveButtonSate: ""
    }
  },
  methods: {
    saveChanges: function() {
      this.saveButtonSate = 'saving'
      update(ref(db, "dppCountdown"), {
          text: this.text,
          loading: "Lade Daten...",
          aktionsende: `${this.formDate} 18:00`,
          setting: "countdown",
          format: "loadingCircle"
        })
        .then(() => {
          this.saveButtonSate = 'done'
          setTimeout(() => this.saveButtonSate = '', 1800)
        })
        .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        })
    }
  },
  async mounted() {
   await onValue(ref(db, "dppCountdown"), (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      this.text = data.text;
      this.formDate = moment(data.aktionsende).tz('Europe/Berlin').format('YYYY-MM-DD');
    }), {
      onlyOnce: true  
    }
    this.loading = false;
    
  }
}
</script>

<style scoped>
#dpp-countdown-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>