<template>
  <b-modal :id="modalId" :ref="modalId" title="Event bearbeiten" no-close-on-backdrop hide-header-close lazy content-class="shadow" scrollable>
    <label for="theme" class="font-weight-bold">Thema</label>
    <b-form-input id="theme" v-model="theme" class="form-group" placeholder="Mein Event"></b-form-input>
    
    <label for="participants" class="font-weight-bold">Teilnehmer</label>
    <p class="small">
      -1 = ausgebucht<br>
      0 = keine Teilnehmerbegrenzung
    </p>
    <b-form-input id="participants" v-model.number="participants" class="form-group" type="number" min="-1"></b-form-input>

    <label for="host" class="font-weight-bold">Sprecher</label>
    <b-form-input id="host" v-model="host" class="form-group" placeholder="Max Mustermann"></b-form-input>

    <label for="language" class="font-weight-bold">Sprache</label>
    <b-form-select id="language" v-model="language" :options="languageOptions" class="form-group"></b-form-select>

    <label for="link" class="font-weight-bold">Link</label>
    <LinkComponent v-on:linkChanged="linkChanged" :linkProp="link" />

    <label for="type" class="font-weight-bold">Typ</label>
    <b-form-select id="type" v-model="type" :options="typeOptions" class="form-group"></b-form-select>

    <label for="tables" class="font-weight-bold">Tabellen</label>
    <p class="small">Mehrfachauswahl: CMD + Klick</p>
    <b-form-select id="tables" v-model="tables" :options="tablesOptions" class="form-group" multiple></b-form-select>

    <label for="date" class="font-weight-bold">Datum</label>
    <b-form-datepicker id="date" v-model="date" locale="de-DE" class="form-group"></b-form-datepicker>

    <label class="font-weight-bold">Uhrzeit</label>
    <b-form-group
      label-cols-sm="1"
      label="Von"
      label-for="from-time"
    >
      <b-form-timepicker id="from-time" v-model="fromTime" :locale="'de'"></b-form-timepicker>
    </b-form-group>
    <b-form-group
      label-cols-sm="1"
      label="Bis"
      label-for="to-time"
    >
      <b-form-timepicker id="to-time" v-model="toTime" :locale="'de'"></b-form-timepicker>
    </b-form-group>

    <label for="videolink" class="font-weight-bold">Videolink</label>
    <b-form-input id="videolink" v-model="videoLink" class="form-group" placeholder="https://www..."></b-form-input>

    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
      <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
    </template>
  </b-modal>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'
import LinkComponent from './LinkComponent'

import { db } from "../plugins/firebase"
import { update, ref } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'EditEventComponent',
  props: {
    selectedKey: String,
    selectedItem: Object,
    modalId: String
  },
  components: {
    SaveButtonComponent,
    LinkComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['edit-event-modal'].hide()
    },
    saveChanges: function() {
      if (
        this.type === '' ||
        this.date === '' ||
        this.fromTime === '' ||
        this.toTime === '' ||
        this.language === '' ||
        this.theme === '' ||
        this.host === '' ||
        this.participants === '' ||
        this.tables.length === 0
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        [this.selectedKey]: {
          type: this.type,
          date: this.date,
          participants: this.participants,
          fromTime: this.fromTime,
          toTime: this.toTime,
          language: this.language,
          theme: this.theme,
          host: this.host,
          link: this.link,
          videoLink: this.videoLink,
          tables: this.tables
        }
      }

      update(ref(db, "events"), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    },
    linkChanged: function(data) {
      this.link = data
    }
  },
  watch: {
    selectedItem: function(data) {
      this.type = data.type
      this.date = data.date
      this.fromTime = data.fromTime
      this.toTime = data.toTime
      this.language = data.language
      this.theme = data.theme
      this.host = data.host
      this.participants = data.participants
      this.link = data.link
      this.videoLink = data.videoLink
      this.tables = data.tables
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.saveButtonSate = ''
    })
  },
  data() {
    return {
      type: '',
      typeOptions: [
        'Messe',
        'Road Show',
        'Training',
        'Webinar'
      ],
      date: '',
      fromTime: '',
      toTime: '',
      language: '',
      languageOptions: [
        'DE',
        'EN',
        'FR'
      ],
      theme: '',
      host: '',
      link: {
        target: '_blank',
        url: '',
        intValue: '',
        type: 'Extern'
      },
      tables: [],
      tablesOptions: [
        'Standard',
        'EDP',
        'EDP Trainings',
        'DPP',
        'DPP Trainings',
        'APP',
        'Frankreich'
      ],
      videoLink: '',
      participants: 0,
      saveButtonSate: ''
    }
  }
}
</script>