<template>
  <div id="text-editor-component">
    <quill-editor 
      v-model="content"
      class="form-group"
      :options="editorOptions">
    </quill-editor>
    <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" /> <i class="ml-2 small">{{this.lastEdited}}</i>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
import SaveButtonComponent from '../components/SaveButtonComponent'
import moment from 'moment-timezone'

import { db } from "../plugins/firebase"
import { ref, onValue, set} from "firebase/database"

export default {
  name: 'TextEditorComponent',
  components: {
    quillEditor,
    SaveButtonComponent
  },
  props: {
    shop: String,
    textType: String
  },
  methods: {
    saveChanges: function() {
      this.saveButtonSate = 'saving'
      const date = moment().tz('Europe/Berlin').locale('de').format('DD.MM.YY')
      const time = moment().tz('Europe/Berlin').locale('de').format('LTS')
      const document = {
        lastEdited:  `Zuletzt bearbeitet am ${date} um ${time} Uhr`,
        content: this.content
      }
      set(ref(db, 'texts/' + this.textType + "/" + this.shop), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.saveButtonSate = '', 2000)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 2000)
      })
    }
  },
  mounted() {
    onValue(ref( db, 'texts') , snapshot => {
      const text = snapshot.val()
      this.content = text[this.textType][this.shop].content
      this.lastEdited = text[this.textType][this.shop].lastEdited
    })
  },
  data() {
    return {
      saveButtonSate: '',
      content: '',
      lastEdited: '',
      editorOptions: {
        theme: 'snow',
        placeholder: 'Text eingeben',
        modules: {
          toolbar: [
            [{ 'header': [4, false] }],
            [
              'bold', 
              'italic'
            ],
            [
              { 'list': 'ordered'}, 
              { 'list': 'bullet' }
            ],
            [
              { 'indent': '-1'}, 
              { 'indent': '+1' }
            ],
            [
              'link'
            ]
          ]
        }
      }
    }
  }
}
</script>