import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    alert: {
      kind: 'danger',
      message: '',
      time: false
    }
  },
  getters: {
    alert(state){
      return state.alert
    }
  },
  mutations: {
    SET_ALERT(state, value) {
      state.alert = value;
    },
  },
  actions: {
    fetchAlert({ commit }, alert) {
      alert.time = alert?.time || true
      commit("SET_ALERT", alert);
    }
  }
});

export { store }