<template>
  <div>
    <b-row>
      <b-col class="">
        <label class="font-weight-bold">{{label}}</label>
      </b-col>
      <b-col class="text-right">
        <p class="mb-0">
          <span @click="changeInputViws('first-language')" :class="show === 'first-language' ? 'language-active' : 'language'">Grundspache</span> 
          /
          <span @click="changeInputViws('translation')" :class="show === 'translation' ? 'language-active' : 'language'">Übersetzung</span>
        </p>
      </b-col>
    </b-row>

    <b-form-input 
      v-if="show==='first-language'" 
      ref="input" v-model="valueData" 
      class="form-group" 
      :placeholder="placeholder" 
      v-on:input="(val)=>{this.update()}"
    />

    <b-form-input 
      v-if="show==='translation'" 
      ref="inputTranlation" 
      v-model="valueTranslationData" 
      class="form-group" :placeholder="placeholderTranslation" 
      v-on:input="(val)=>{this.update()}"
    />

  </div>
</template>

<script>

export default {
  name: 'InputLanguageSwitch',
  props: {
    label: String,
    placeholder: String,
    placeholderTranslation: String,
    value: String,
    valueTranslation: String
  },
  watch: {
  },
  methods: {
    hideModal: function() {
      this.$refs['edit-contact-modal'].hide()
    },
    update: function() {
      this.$emit('value', this.valueData)
      this.$emit('value-translation', this.valueTranslationData)
    },
    changeInputViws(el) {
      this.show = el
    }
  },
  data() {
    return {
      show: 'first-language',
      valueData: this.value,
      valueTranslationData: this.valueTranslation,
    }
  }
}
</script>
<style scoped>

  .language:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  .language-active {
    color: #007bff;
  }
</style>