<template>
  <div>
    <b-form-file v-on:input="saveImage" accept=".jpg, .png" lang="de" data-max-size="1000" placeholder="Datei auswählen oder hierher ziehen und ablegen..." drop-placeholder="Datei hier ablegen..."></b-form-file>
    <div v-if="isLoading === false">
      <div v-if="image !== ''" class="mt-3">
        <label class="font-weight-bold">Neues Bild</label><br>
        <img :src="image" class="img-fluid" id="image">
      </div>
    </div>
    <SpinnerComponent class="mt-3" v-else />
</div>
</template>

<script>
import SpinnerComponent from './SpinnerComponent'
import { storage } from "../plugins/firebase"
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

import { store } from "../plugins/store"

export default {
  name: 'UploadImageComponent',
  components: {
    SpinnerComponent
  },
  props: {
    imageProp: String
  },
  computed: {
    fileName: function() {
      const alphaNum = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let fileName = ''
      for (let i = 0; i < 16; i++) {
        fileName += alphaNum.charAt(Math.floor(Math.random() * alphaNum.length))
      }
      return fileName
    }
  },
  methods: {
    saveImage: function(file) {
      this.isLoading = true
      
      let fileSuffix = '.jpg'
      if (file.type === 'image/png') {
        fileSuffix = '.png'
      }

      //const fileName = this.fileName + fileSuffix
      
      this.uploadImage(file, fileSuffix)
      .then(() => {
        this.getImageUrl(fileSuffix)
        .then(url => {
          this.image = url
          this.$emit('imageUpload', {
            fileName: `${this.fileName}${fileSuffix}`,
            url: url
          })
        })
        .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
      })
      .then(() => this.isLoading = false)
      .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
    },
    uploadImage: function(file, fileSuffix) {
      return new Promise((resolve, reject) => {
        const config = {
          contentType: file.type
        }
        
        uploadBytes(storageRef(storage, `${this.fileName}${fileSuffix}`), file, config)
        .then(() => {
            resolve()
        })
        .catch(error => reject(error)) // eslint-disable-line no-console
      })
    },
    getImageUrl: function(fileSuffix) {
      return getDownloadURL(storageRef(storage, `${this.fileName}${fileSuffix}`))
    }
  },
  mounted() {
    if (this.imageProp !== '' && this.imageProp !== undefined) {
      this.image = this.imageProp
    }
  },
  data() {
    return {
      isLoading: false,
      image: ''
    }
  }
}
</script>

<style scoped>
.custom-file-label::after {
  content: 'Durchsuchen';
}
</style>