<template>
  <div id="link-component">
    <b-form-select v-model="type" :options="typeOptions" class="mb-2" v-on:change="changeType"></b-form-select>
    <div v-if="type === 'Extern'">
      <b-form-select v-model="target" :options="targetOptions" class="mb-2"></b-form-select>
      <b-form-input v-model="url" class="form-group" placeholder="https://www..."></b-form-input>
    </div>
    <div v-else-if="type === 'Mail Popup'">
      <b-form-input v-model="reciever" class="form-group" placeholder="Empfänger (E-Mail-Adresse)"></b-form-input>
      <b-form-input v-model="subject" class="form-group" placeholder="Subject"></b-form-input>
      <b-form-textarea
        v-model="body"
        placeholder="Body ..."
        rows="3"
        max-rows="15"
        class="form-group"
      ></b-form-textarea>
    </div>
    <div v-else>
      <b-form-select v-model="url" :options="intUrlOptions" class="mb-2"></b-form-select>
      <b-form-input v-model="intValue" class="form-group" :placeholder="intValuePlaceholder"></b-form-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkComponent',
  props: {
    linkProp: Object
  },
  watch: {
    url: function(value) {
      if (value === '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=direktanzeige&seoartikelnummer=') {
        this.intValuePlaceholder = 'ABC-123456'
      }

      if (value === '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?sTICKCOUNT=<!--4DHTMLVAR sTICKCOUNT-->&action=sucheHersteller&artikelhersteller=') {
        this.intValuePlaceholder = 'Apple'
      }

      if (value === '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=SucheFS&bezeich=1&nummern=1&suchtextfeld=') {
        this.intValuePlaceholder = 'iPad Pro'
      }

      if (value === '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=sendhtml&htmlpage=') {
        this.intValuePlaceholder = 'seite.html'
      }

      if (value === '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=SucheFS&suchbegriff=1&suchtextfeld=') {
        this.intValuePlaceholder = 'zzz_Alle Produkte'
      }
    },
    link: function(data) {
      data.intValue = encodeURI(data.intValue)
      this.$emit('linkChanged', data)
    }
  },
  methods: {
    changeType: function(value) {
      if (value === 'Intern') {
        this.target = '_self'
        this.intValue = ''
        this.url = '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=direktanzeige&seoartikelnummer='
      } else if (value === 'Mail Popup'){
        this.reciever = ''
        this.subject = ''
        this.body = ''
        this.intValue = ''
      } else {
        this.url = ''
        this.intValue = ''
      }
    }
  },
  computed: {
    link: function() {
      return {
        target: this.target,
        url: this.url,
        reciever: this.reciever,
        subject: this.subject,
        body: this.body,
        intValue: this.intValue,
        type: this.type
      }
    }
  },
  mounted() {
    if (this.linkProp !== undefined) {
      if (this.linkProp.target !== '') {
        this.target = this.linkProp.target
      }

      if (this.linkProp.url !== '') {
        this.url = this.linkProp.url
      }

      if (this.linkProp.reciever !== '' && this.linkProp.reciever !== undefined) {
        this.reciever = this.linkProp.reciever
      }

      if (this.linkProp.subject !== '' && this.linkProp.subject !== undefined) {
        this.subject = this.linkProp.subject
      }

      if (this.linkProp.body !== '' && this.linkProp.body !== undefined) {
        this.body = this.linkProp.body
      }

      if (this.linkProp.intValue !== '') {
        this.intValue = decodeURI(this.linkProp.intValue)
      }

      if (this.linkProp.type !== '') {
        this.type = this.linkProp.type
      }
    }
  },
  data() {
    return {
      type: 'Extern',
      target: '_self',
      url: '',
      reciever: '',
      subject: '',
      body: '',
      intValue: '',
      intValuePlaceholder: 'ABC-123456',
      typeOptions: [
        'Extern',
        'Intern',
        'Mail Popup'
      ],
      targetOptions: [
        {
          text: 'Selbes Fenster/Tab',
          value: '_self'
        }, {
          text: 'Neues Fenster/Tab',
          value: '_blank'
        }
      ],
      intUrlOptions: [
        {
          text: 'Artikelnummer',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=direktanzeige&seoartikelnummer='
        }, {
          text: 'Hersteller',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=sucheHersteller&warenklist=&warengruppenlist=&Suchid=0&Suchstartpos=1&pagetype=warengruppen&ust_box=1&sortierfeld=0&ButtonName=hersteller_auswahl&artikelhersteller='
        }, {
          text: 'Leerschritt-Artikelnummer',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=direktanzeige&seoartikelnummer='
        }, {
          text: 'Schnellsuche',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=SucheFS&bezeich=1&nummern=1&suchtextfeld='
        }, {
          text: 'Seite',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=sendhtml&htmlpage='
        }, {
          text: 'Suchbegriff',
          value: '/<!--4DHTMLVAR sKontaktID-->/<!--4DHTMLVAR sKontaktKEY-->/4DCGI/ezshop?action=SucheFS&suchbegriff=1&suchtextfeld='
        }
      ]
    }
  }
}
</script>