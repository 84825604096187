import Vue from 'vue'
import App from './App.vue'

//moment
import moment from 'moment-timezone'

import { auth } from "./plugins/firebase"
import { onAuthStateChanged } from "firebase/auth"

import Alert from './smallComponents/Alert.vue'

// Vue Router
import router from './plugins/router'

// Font Awesome
import './plugins/fontawesome'

import { store } from "./plugins/store";

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import './assets/custom.scss'
Vue.use(BootstrapVue)

// Hide Production Tip
Vue.config.productionTip = false
Vue.component('ShowAlert', Alert)
Vue.filter('convertDate', (date) => {
  return moment(date).format('DD.MM.YY')
})

let app = ''

// App Init
onAuthStateChanged(auth, async () => {

  if (!app) {
    app = new Vue({
      render: h => h(App),
      router,
      store,
    }).$mount('#app')

  }
})
