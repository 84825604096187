<template>
  <div id="create-social-media-icon-component">
    <button class="btn btn-primary" v-b-modal="'create-social-media-icon-modal'">
      <fa-icon :icon="['fas','plus']" class="mr-2" />Erstellen
    </button>
    <b-modal id="create-social-media-icon-modal" ref="create-social-media-icon-modal" title="Icon erstellen" no-close-on-backdrop hide-header-close content-class="shadow" scrollable>
      <label for="name" class="font-weight-bold">Name</label>
      <b-form-input id="name" v-model="name" class="form-group"></b-form-input>

      <label for="iconName" class="font-weight-bold">Font Awesome Icon Name</label>
      <p class="small d-flex align-items-center">
        <a href="https://fontawesome.com/icons?d=gallery&s=brands" target="_blank"><fa-icon :icon="['fas','info-circle']" class="mr-1" />https://fontawesome.com/icons?d=gallery&s=brands</a>
      </p>
      <b-form-input id="iconName" v-model="iconName" placeholder="fa-instagram" class="form-group"></b-form-input>

      <label for="color" class="font-weight-bold">Hintergrundfarbe</label>
      <b-form-input id="color" v-model="color" placeholder="#000000" class="form-group"></b-form-input>
      
      <label for="link" class="font-weight-bold">Link</label>
      <b-form-input id="link" v-model="link" class="form-group"></b-form-input>

      <label for="link" class="font-weight-bold">Shops</label>
      <p class="small">Mehrfachauswahl: CMD + Klick</p>
      <b-form-select v-model="selectedShops" :options="shops" multiple :select-size="4"></b-form-select>

      <template v-slot:modal-footer>
        <b-button variant="outline-secondary" v-on:click="hideModal">Abbrechen</b-button>
        <SaveButtonComponent :saveButtonSate="saveButtonSate" v-on:submitSaveButton="saveChanges" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SaveButtonComponent from './SaveButtonComponent'

import {db} from "../plugins/firebase"
import {set, ref, push, child} from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'CreateSocialMediaIconComponent',
  components: {
    SaveButtonComponent
  },
  methods: {
    hideModal: function() {
      this.$refs['create-social-media-icon-modal'].hide()
    },
    saveChanges: function() {
      if (
        this.name === '' ||
        this.iconName === '' ||
        this.color === '' ||
        this.link === '' ||
        this.selectedShops.length === 0
      ) {
        return
      }

      this.saveButtonSate = 'saving'

      const document = {
        name: this.name,
        iconName: this.iconName,
        color: this.color,
        link: this.link,
        selectedShops: this.selectedShops
      }
      const newPostKey = push(child(ref(db), 'socialMediaIcons')).key;
      set(ref(db, 'socialMediaIcons/'+newPostKey), document)
      .then(() => {
        this.saveButtonSate = 'done'
        setTimeout(() => this.hideModal(), 1800)
      })
      .catch(error => {
        this.saveButtonSate = 'error'
         store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        console.error(error)
        setTimeout(() => this.saveButtonSate = '', 1800)
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.name = ''
      this.iconName = ''
      this.color = ''
      this.link = ''
      this.saveButtonSate = ''
      this.selectedShops = []
    })
  },
  data() {
    return {
      name: '',
      iconName: '',
      color: '',
      link: '',
      saveButtonSate: '',
      selectedShops: [],
      shops: [
        {
          text: 'CLRent',
          value: 'clrent'
        },
        {
          text: 'Retail',
          value: 'retail'
        },
        {
          text: 'Frankreich',
          value: 'fr'
        },
        {
          text: 'Großbritannien',
          value: 'uk'
        },
        {
          text: 'Standard',
          value: 'standard'
        }
      ]
    }
  }
}
</script>