<template>
  <tr id="social-media-icon-row-component">
    <td><button class="btn btn-link p-0" v-on:click="selectItem">{{item.name}}</button></td>
    <td>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary btn-sm mr-2" v-on:click="duplicate(item)" v-b-tooltip.hover title="Duplizieren"><fa-icon :icon="['fad','clone']" /></button>
        <button class="btn btn-danger btn-sm" v-on:click="deleteFromDatabase" v-b-tooltip.hover title="Löschen"><fa-icon :icon="['fas','trash']" /></button>
      </div>
    </td>
  </tr>
</template>

<script>
import {db} from "../plugins/firebase"
import {ref, set, remove, push, child } from "firebase/database"

import { store } from "../plugins/store"

export default {
  name: 'SocialMediaIconRowComponent',
  props: {
    item: Object,
    itemKey: String
  },
  methods: {
    selectItem: function() {
      this.$emit('selectedItem', {
        item: this.item,
        key: this.itemKey
      })
    },
    deleteFromDatabase: function() {
      this.$bvModal.msgBoxConfirm(`Möchtest du "${this.item.name}" wirklich löschen?`, {
        title: '',
        okVariant: 'danger',
        okTitle: 'OK',
        cancelTitle: 'Abbrechen',
        size: 'sm',
        contentClass: 'shadow',
        hideHeaderClose: false,
        noCloseOnBackdrop: true
      })
      .then(value => {
        if (value === true) {

          remove(ref(db, 'socialMediaIcons/' + this.itemKey))
          .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
        }
      })
      .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        }) // eslint-disable-line no-console
    },
    duplicate: function(document) {
      document.name += ' - Kopie'
      const newPostKey = push(child(ref(db), 'socialMediaIcons')).key;
      set(ref(db, 'socialMediaIcons/' + newPostKey), document)
      .catch((error) => {
          store.dispatch("fetchAlert", {
            kind: 'danger',
            message: error,
            time: false
          })
        })
    }
  }
}
</script>

<style scoped>
  tr:first-of-type td {
    border: 0;
  }
</style>