// Vue
import Vue from 'vue'

// Vue Router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Firebase
import {auth} from "../firebase"

// Routes
import routes from './routes.js'

// Router Instance
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {

  const currentUser = await auth.currentUser
  const getIdTokenResult = currentUser ? await currentUser.getIdTokenResult(): false
  const isUser = getIdTokenResult ? getIdTokenResult.claims.user : false
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if ((to.name === 'SignInView' || to.name === 'NotAllowed') && currentUser && isUser) next({ name: 'DashboardView' })
  else if (to.name !== 'NotAllowed' && !isUser && currentUser && requiresAuth) next({ name: 'NotAllowed' })
  else if (requiresAuth && currentUser) next()
  else if (requiresAuth && !currentUser) next({ name: 'SignInView' })
  else next()
})

export default router