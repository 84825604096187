<template>
  <div id="page-title-component" class="d-flex flex-column justify-content-center">
    <small class="text-muted mb-1 text-uppercase"><fa-icon :icon="categoryIcon" fixed-width class="mr-1" />{{category}}</small>
    <h1 class="h2 m-0">{{pageTitle}}</h1>
  </div>
</template>

<script>
export default {
  name: 'PageTitleComponent',
  props: {
    pageTitle: String,
    categoryIcon: String,
    category: String
  }
}
</script>