<template>
  <router-view id="app" class="w-100"></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
h1, th {
  color: #5c5c5c;
}

#content {
  margin-left: 250px;
  display: flex;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.main-label {
  font-weight: 700;
}
.text-muted-light {
  color: #8a929a !important;
}
[id$="BV_tab_controls_"] {
  padding: 1rem !important;
  border-right: 1px solid #dee2e6;
}
.table th {
	border-top: none !important;
}
.custom-file-input:lang(de) ~ .custom-file-label::after {
  content: 'Durchsuchen' !important;
}
.mx-datepicker-main.mx-datepicker-popup {
  left: 50% !important;
  transform: translateX(-50%);
}
.ql-toolbar {
  border-radius: 5px 5px 0 0 !important;
  border: 1px solid #ced4da !important;
  background-color: #fff !important;
}
.ql-container {
  border-radius: 0 0 5px 5px !important;
  border: 1px solid #ced4da !important;
  border-top: none !important;
  background-color: #fff !important;
}
[id$="BV_tab_controls_"] {
	padding: 0 !important;
  border-right: none;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	background-color: #f8f9fa !important;
	border-color: #dee2e6 #dee2e6 #f8f9fa !important;
}
</style>