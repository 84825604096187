<template>
  <div id="dashboard-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Dashboard" category="Main" categoryIcon="home" />
    </div>
    <div class="pl-3 py-3">
      <div class="alert alert-warning" @click="set">
        Hier gibt es noch nichts zu sehen. 🙃<br>
        Falls ihr gute Ideen habt, immer her damit.
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from '../components/PageTitleComponent'
import { store } from '../plugins/store'

export default {
  name: 'DashboardView',
  components: {
    PageTitleComponent
  },
  methods:{
    set(){
      store.dispatch("fetchAlert", {
        kind: 'info',
        message: "Glückwunsch, du hast das Easter Egg gefunden. 🥚",
        time: 30
      })
    }
  }
}
</script>

<style scoped>
#dashboard-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>