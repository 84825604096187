<template>
  <div id="contacts-view">
    <div class="d-flex justify-content-between align-items-center title-block border-bottom ml-3">
      <PageTitleComponent pageTitle="Kontakte" category="Sonstiges" categoryIcon="cogs" />
      <div class="d-flex">
        <CreateContactComponent class="mr-2" />
        <ChangeOrderComponent :entriesProp="contactsSorted" :dbRoot="'contacts'" :size="'xl'" />
      </div>
    </div>
    <div class="pl-3">
      <div class="mt-2 alert alert-warning" v-if="contacts === null">Hier gibt es noch nichts zu sehen. Klicke auf "Erstellen" um das zu ändern. 😉</div>
      <table class="table mt-3" v-else-if="Object.keys(contacts).length > 0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Shops</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ContactRowComponent v-for="(item, key) in contactsSorted" :key="`contact-${key}`" :item="item" :itemKey="key" v-on:selectedItem="selectItem" />
        </tbody>
      </table>
      <SpinnerComponent class="mt-3" v-else />
    </div>
    <EditContactComponent 
      :modalId="'edit-contact-modal'" 
      :selectedKey="selectedKey"
      :selectedItem="selectedItem" />
  </div>
</template>

<script>
  import PageTitleComponent from '../components/PageTitleComponent'
  import ContactRowComponent from '../components/ContactRowComponent'
  import SpinnerComponent from '../components/SpinnerComponent'
  import CreateContactComponent from '../components/CreateContactComponent'
  import EditContactComponent from '../components/EditContactComponent'
  import ChangeOrderComponent from '../components/ChangeOrderComponent'
  import _ from 'lodash'

  import { db } from "../plugins/firebase"
  import { onValue, ref } from "firebase/database"

  export default {
    name: 'ContactsView',
    components: {
      PageTitleComponent,
      ContactRowComponent,
      SpinnerComponent,
      CreateContactComponent,
      EditContactComponent,
      ChangeOrderComponent
    },
    methods: {
      selectItem: function(data) {
        this.selectedKey = data.key
        this.selectedItem = data.item
        this.$bvModal.show('edit-contact-modal')
      }
    },
    computed: {
      contactsSorted: function() {
        const contacts = Object.entries(this.contacts).map(([key, value]) => {
          return [key, value]
        })

        const sorted = _.orderBy(contacts, [(item) => {
          return item[1].order
        }], ['asc'])

        return Object.fromEntries(sorted)
      }
    },
    mounted() {
      onValue(ref(db, 'contacts'), snapshot => {
        this.contacts = snapshot.val()
      })
    },
    data() {
      return {
        contacts: {},
        selectedKey: '',
        selectedItem: {}
      }
    }
  }
</script>

<style scoped>
#contacts-view {
  margin-left: 250px;
}
.title-block {
  height: 120px;
}
</style>